import React from "react";
// import styled
import styled from "styled-components";
// images
import Wave2 from "../img/waves/wave (2).svg";
import Wave1 from "../img/waves/wave (1).svg";
import { testimonials } from "../util";

const Services = () => {
  return (
    <StyledServices>
      <div className="icons gradient" id="ponuda">
        <img src={Wave2} alt="" className="svg-img-top" />
        <div className="container icons-container">
          <h1 className="text-center ponuda-h1 pb-3">
            Zašto izabrati naš centar?
          </h1>
          <div className="row text-center ponude">
            <div className="col-md-4 px-2 ponuda ponuda-1">
              <div className="icon">
                <i className="fas fa-user-tie"></i>
              </div>
              <h3>Profesionalnost</h3>
              <p>
                {testimonials.one1}
                <br />
                {testimonials.one2}
              </p>
            </div>
            <div className="col-md-4 px-2 ponuda ponuda-2">
              <div className="icon">
                <i className="fas fa-calendar-check"></i>
              </div>
              <h3>Fleksibilnost</h3>
              <p>
                {testimonials.two1}
                <br />
                {testimonials.two2}
              </p>
            </div>
            <div className="col-md-4 ponuda ponuda-3">
              <div className="icon">
                <i className="fas fa-book"></i>
              </div>
              <h3>Učenje</h3>
              <p>{testimonials.three1}</p>
            </div>
          </div>
        </div>
        <img src={Wave1} alt="" className="svg-img" />
      </div>
    </StyledServices>
  );
};

const StyledServices = styled.div`
  p {
    font-size: 1.2rem;
  }
  .gradient {
    background: rgb(238, 174, 202);
    background: radial-gradient(
      circle at top,
      rgba(238, 174, 202, 1) 0%,
      rgba(148, 187, 233, 1) 100%
    );
  }
  .icon {
    color: black;
    height: 5rem;
    width: 5rem;
    font-size: 1.75rem;
    border-radius: 100%;
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }
  .fas {
    font-size: 2.3rem;
  }
  .svg-img {
    margin-bottom: -1rem;
  }
`;

export default Services;
