import React, { useState } from "react";
// Carousel
import { Carousel, Button } from "react-bootstrap";
// Router
import { Link } from "react-router-dom";
// styled
import styled from "styled-components";
// Images
import Image1 from "../img/img1.jpg";
import Image2 from "../img/img2.jpg";
import Image3 from "../img/img3.jpg";
// icons
import { AiOutlineArrowLeft } from "react-icons/ai";
// animations
import { motion } from "framer-motion";
import { fade } from "../anim";

const Slider = () => {
  const [details1, setDetails1] = useState(true);
  const [details2, setDetails2] = useState(true);

  return (
    <StyledSlider>
      <Carousel fade keyboard>
        <Carousel.Item>
          <img className="d-block w-100" src={Image1} alt="First slide" />
          <Carousel.Caption className="slide-text first-text">
            {details1 ? (
              <>
                <motion.h1 variants={fade}>
                  Upis je u toku! Upiši se i postani dio porodice.
                </motion.h1>
                <motion.p variants={fade}>
                  Rastemo zajedno! Razvijanje samopouzdanja u razumijevanju i
                  govoru stranog jezika.
                </motion.p>
                <motion.div variants={fade} className="dugmad">
                  <Button
                    variant="light"
                    className="m-2"
                    onClick={() => setDetails1(false)}
                  >
                    Saznajte više.
                  </Button>
                  <Button variant="light" className="m-2" as={Link} to="/upis">
                    Upiši se!
                  </Button>
                </motion.div>
              </>
            ) : (
              <StyledAddition>
                <AiOutlineArrowLeft
                  onClick={() => setDetails1(true)}
                  className="arrow-left"
                />
                <div className="opened-text">
                  <p>
                    Kurs za predškolce obuhvata veliki broj aktivnosti koje se
                    smjenjuju, kako bi se održala pažnja djece, a sadrži i
                    elemente Montesori metode. Kursevi engleskog jezika za djecu
                    od prvog razreda osnovne škole odvijaju se u skladu sa YLE
                    jezičkom skalom (Starters, Movers, Flyers), odnosno prate
                    CEFR jezičku skalu. Kursevi za tinejdžere se odvijaju od A1
                    do C2 nivoa i sadrže pripremu za polaganje nekog od
                    Cambridge međunarodnog ispita.
                  </p>
                </div>
              </StyledAddition>
            )}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Image3} alt="Second slide" />
          <Carousel.Caption className="slide-text">
            {details2 ? (
              <>
                <h1>Kursevi za najmlađe i odrasle.</h1>
                <p>
                  Još uvijek čekate? Počnite sa učenjem engleskog, njemačkog i
                  grčkog jezika.
                </p>
                <Button variant="light" onClick={() => setDetails2(false)}>
                  Saznajte više.
                </Button>
              </>
            ) : (
              <StyledAddition>
                <AiOutlineArrowLeft
                  onClick={() => setDetails2(true)}
                  className="arrow-left"
                />
                <div className="opened-text">
                  <p>
                    Engleski jezik - opšti na svim nivoima od A1 do C2,
                    specijalizovani kursevi za one koji su na B1+ nivou,
                    individualno ili u malim grupama (4-8 polaznika). <br />
                    Njemački jezik - kursevi njemačkog na svim nivoima za djecu
                    i odrasle. Pripremna nastava za polaganje Gete ispita.
                    <br />
                    Grčki jezik - kursevi grčkog na svim nivoima za
                    djecu i odrasle.
                  </p>
                </div>
              </StyledAddition>
            )}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Image2} alt="Third slide" />
          <Carousel.Caption className="slide-text">
            <>
              <h1>Sudski tumač za engleski jezik.</h1>
              <p>
                Usluge pismenog i usmenog prevoda, sa ili bez ovjere sudskog
                tumača za engleski jezik.
              </p>
              <Link to="/sudski-tumac">
                <Button variant="light">Saznajte više.</Button>
              </Link>
            </>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </StyledSlider>
  );
};

const StyledSlider = styled.div`
  p {
    font-size: 1.1rem;
  }
  position: relative;
  img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
  }
  .opened-text {
    background: rgba(0, 0, 0, 0.3);
    color: white;
    font-weight: bolder;
    border-radius: 12px;
    padding: 5px 5px 0.5px 5px;
  }
  .slide-text {
    position: absolute;
    top: 30%;
    left: 10%;
    width: 60%;
    font-weight: bolder;
    h1 {
      font-size: 4rem;
      font-weight: 900;
    }
  }

  @media screen and (max-width: 1020px) {
    h1 {
      font-size: 3rem;
    }
    .slide-text {
      top: 13%;
      left: 15%;
    }
  }
  @media screen and (max-width: 480px) {
    .slide-text h1 {
      font-size: 3rem;
      width: 100%;
    }
    .opened-text {
      top: 10%;
    }
    .first-text {
      top: 2%;
    }
    .dugmad {
      margin-left: -1rem;
      width: 297px;
    }
  }
  @media screen and (max-width: 300px) {
    .slide-text h1 {
      font-size: 2.7rem;
    }
  }
`;

const StyledAddition = styled(motion.div)`
  position: absolute;
  width: 70%;
  margin: auto;
  .arrow-left {
    cursor: pointer;
    font-size: 2.3rem;
    margin-bottom: 0.7rem;
  }
  .arrow-left:hover {
    transform: scale(1.2);
  }
  @media screen and (max-width: 560px) {
    width: 100%;
    top: -13%;
  }
  @media screen and (max-width: 375px) {
    p {
      font-size: 0.7rem;
    }
  }
`;

export default Slider;
