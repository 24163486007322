import React from "react";
// styled
import styled from "styled-components";

const Card = (props) => {
  return (
    <StyledCard>
      <h2>
        <b>{props.text}</b>
      </h2>
      <h4>Objavljeno: {props.date}</h4>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle at top,
    rgba(238, 174, 202, 0.7) 0%,
    rgba(148, 187, 233, 0.9) 100%
  );
  text-decoration: none;
  color: black;
  min-height: 40vh;
  box-shadow: 0px 5px 20px rgba(107, 187, 252, 0.226);
  text-align: center;
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 1rem;
  overflow: hidden;
  transition: all 0.5s ease;
  cursor: pointer;
  h4 {
    color: #494949;
    font-size: 1rem;
    margin-top: 4rem;
  }
  &:hover {
    text-decoration: none;
    transform: scale(1.1);
  }
  @media screen and (max-width: 993px) {
    min-height: 240px;
  }
  @media screen and (max-width: 767px) {
    min-height: 200px;
  }
`;

export default Card;
