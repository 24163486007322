import React from "react";
// styled
import styled from "styled-components";
// Images
import Image1 from "../components/news/images/image4.jpg";
// data
import { comments } from "../util";
// bootstrap
import { Col, Row, Container } from "react-bootstrap";

const Comment = () => {
  return (
    <StyledComment>
      <div className="testimonials">
        <h1 className="text-center mb-4" id="komentari">
          Komentar predavača.
        </h1>
        <Container>
          <Row className="justify-content-center align-items-center flex">
            <Col md className="text-center">
              <img src={Image1} className="img-fluid testimonials-img" alt="" />
              <p className="mt-2">{comments[0].author}</p>
            </Col>
            <Col md className="quote text-center">
              <i className="fas fa-quote-right"></i>
              <p className="text-center">{comments[0].text}</p>
            </Col>
          </Row>
          {/* <Row className="justify-content-center align-items-center my-3 reverse">
            <Col className="quote text-center">
              <i className="fas fa-quote-right"></i>
              <p className="text-center">{comments[1].text}</p>
            </Col>
            <Col className="text-center">
              <img src={Image2} className="img-fluid testimonials-img" alt="" />
              <p className="my-2">{comments[1].author}</p>
            </Col>
          </Row> */}
        </Container>
      </div>
    </StyledComment>
  );
};

const StyledComment = styled.div`
  p {
    font-size: 1.2rem;
  }
  position: relative;
  .testimonials-img {
    border-radius: 100%;
    border: 3px solid #bebebe;
    width: 60%;
    margin: auto;
  }
  .quote p {
    font-weight: 600;
  }
`;

export default Comment;
