export const aboutText = {
  one: "Centar InLingua je otvorio svoja vrata u februaru 2021. godine. Pored ogromne ljubavi prema poslu kojim se bavi već 10 godina, vlasnica je na ideju o osnivanju Centra za strane jezike „InLingua“ došla upravo zbog toga što privatni centri, kao neformalni element u učenju stranih jezika, imaju manje grupe učenika, a časovi stavljaju akcenat na praktično znanje.",
  two: "Naša misija je da edukaciji polaznika pristupamo sa posebnom pažnjom, isključivo putem primjene najkvalitetnijih programa. InLingua tim čine mladi, ambiciozni i iskusni predavači, posvećeni radu i unapređenju svojih vještina.",
  three:
    "Dođite, upoznajte nas i provjerite kako mi, i dok rastemo, pravimo razliku.",
};

export const comments = [
  {
    author: "Danijela Simić, prof. engleskog jezika.",
    text: "Engleski jezik glavni je jezik u svim školama stranih jezika, tako je i u centru InLingua. Upisom u naš Centar postajete dio porodice. Rastemo zajedno.",
  },
  {
    author: "Dragana Tomić, predavač njemačkog jezika.",
    text: "Učenje njemačkog jezika nije teško kao što se priča, a može biti i zabavno. Ukoliko želite da naučite njemački na što jednostavniji način, sa akcentom na komunikaciju, centar InLingua je svakako pravo mjesto za to.",
  },
];

export const testimonials = {
  one1: "Nastavu u Centru InLingua izvode diplomirani profesori jezika sa višegodišnjim iskustvom u radu, koristeći nastavne materijale i metodički pristup u skladu sa najvišim standardima struke.",
  one2: "Rad se odvija u malim grupama, tako da je posvećenost svakom polazniku maksimalna.",
  two1: "Prilagođavamo se vašim zahtjevima i ciljevima, stoga izdvajamo i tailor-made kurseve jezika koji se odvijaju u mini grupama, a namijenjeni su i firmama, gdje su programi skrojeni po vašoj mjeri.",
  two2: "Naša snaga leži u neizmernoj ljubavi prema poslu, stručnosti, kvalitetu i želji da naši polaznici i mi rastemo zajedno.",
  three1:
    "Za uspješno savladavanje svakog jezika postoji jednostavan recept: učenje i motivacija. Mnogi vjeruju da samo djeca imaju sposobnost brzog učenja. Tačno je da djeca brže pamte, ali odrasle osobe posjeduju veći nivo opšteg znanja i dublje poznavanje raznih tema. Mi ćemo vam pokazati koliko i kako učenje stranog jezika može biti zanimljivo u bilo kojoj dobi.",
};
