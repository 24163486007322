import React from "react";

const Location = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2832.96615
                39761837!2d19.200018714922866!3d44.761104988102396!2m3!1f0!2f0!3f0!3m
                2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475be8d89b17c0cd%3A0xe0887a558915f81
                d!2z0JjQstC1INCQ0L3QtNGA0LjRm9CwIDEwLCDQkdC40ZjQtdGZ0LjQvdCwIDc2MzAw!5
                e0!3m2!1ssr!2sba!4v1615494977552!5m2!1ssr!2sba"
      width="370"
      height="330"
      title="Lokacija"
      loading="fast"
    />
  );
};

export default Location;
