import React from "react";
// Styled
import styled from "styled-components";
// Images
import Wave3 from "../img/waves/wave (3).svg";
// Location
import Location from "./Location";

const Contact = () => {
  return (
    <StyledContact>
      <div className="contact gradient pb-3" id="kontakt">
        <img src={Wave3} alt="" className="svg-img-top" />
        <div className="container justify-content-center align-items-center">
          <h1 className="text-center kontakt-naslov">Kontakt i adresa.</h1>
          <div className="row d-flex justify-content-between">
            <div className="col-lg-3 map text-center">
              <Location />
            </div>
            <div className="col-lg-3 email-form">
              <form
                id="my-form"
                action="https://formspree.io/f/xzbypbjl"
                method="POST"
              >
                <div className="form-group mb-2">
                  <label htmlFor="exampleFormControlInput1">
                    Email adresa:
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="Vaš e-mail"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    Vaša poruka:
                  </label>
                  <textarea
                    name="message"
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Vaša poruka"
                    rows="5"
                  ></textarea>
                  <button
                    type="submit"
                    name="submit"
                    id="my-form-button"
                    className="btn btn-light my-2 contact-btn"
                  >
                    Send
                  </button>
                  <p id="my-form-status"></p>
                </div>
              </form>
            </div>
            <div className="col-lg-4 text-center kontakt-brojevi">
              <h1>Kontakt broj:</h1>
              <ul className="contact-brojevi">
                <li className="contact-broj">
                  066-198/679 - <u>broj škole.</u>
                </li>
              </ul>
              <div className="container d-flex justify-content-center social">
                <a href="https://www.instagram.com/centar_inlingua/">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.facebook.com/Centar-za-strane-jezike-100164368729885">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="https://www.google.com/maps/place/%D0%98%D0%B2%D0%B5+%D0%90%D0%BD%D0%B4%D1%80%D0%B8%D1%9B%D0%B0+10,+%D0%91%D0%B8%D1%98%D0%B5%D1%99%D0%B8%D0%BD%D0%B0+76300/data=!4m2!3m1!1s0x475be8d89b17c0cd:0xe0887a558915f81d?sa=X&ved=2ahUKEwiS1fWegsTvAhWJz4UKHenmCSsQ8gEwAHoECAMQAQ">
                  <i className="fas fa-map-marker-alt"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <img src="./img/waves/wave (1).svg" alt="" className="svg-img" /> */}
      </div>
    </StyledContact>
  );
};

const StyledContact = styled.div`
  .kontakt-naslov {
    margin-bottom: 4rem;
    margin-top: -7rem;
  }
  .contact-broj {
    font-size: 1.2rem;
  }

  .email-form {
    margin-left: 8rem;
  }

  .social i {
    color: black;
    margin: 0 2rem;
    font-size: 2.5rem;
    &:hover {
      transform: scale(1.2);
    }
  }
  .gradient {
    background: rgb(238, 174, 202);
    background: radial-gradient(
      circle at top,
      rgba(238, 174, 202, 1) 0%,
      rgba(148, 187, 233, 1) 100%
    );
  }
  @media only screen and (max-width: 991.4px) {
    .email-form {
      margin: 1rem auto;
    }
    .kontakt-naslov {
      margin-top: 0rem;
    }
  }
  @media only screen and (max-width: 588px) {
    .kontakt-naslov {
      margin-bottom: 4rem;
      margin-top: 0.5rem;
    }
  }
`;

export default Contact;
