import React from "react";
// styled
import styled from "styled-components";

const Footer = () => {
  return (
    <StyledFooter>
      <div className="footer">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-4 footer-copyright text-center">
              <p>InLingua Bijeljina ©2021. || All rights reserved.</p>
            </div>
            <div className="col-md-3 footer-a text-center">
              <p>
                <a
                  href="https://www.instagram.com/mihailoelez/"
                  className="my-page"
                >
                  Kreirao i održava Mihailo Elez.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  p {
    font-size: 1.2rem;
  }
  .footer {
    background-color: black;
    color: white;
    font-size: 1rem;
    height: auto;
  }

  .footer-a,
  .footer-copyright {
    margin-top: 2rem;
  }

  a.my-page {
    color: white;
  }
  .my-page:hover {
    font-size: 1.1rem;
    transition: all 0.5s ease;
  }
  @media screen and (max-width: 767px) {
    .footer-a {
      margin: auto;
    }
  }
`;

export default Footer;
