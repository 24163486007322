import React from "react";
// data
import news from "../../components/news/data";
// styled
import styled from "styled-components";
// images
import img1 from "../../components/news/images/b2b1.jpg";
// bootstrap
import { Container, Row, Col } from "react-bootstrap";
// animations
import { motion } from "framer-motion";
import { pageAnimation } from "../../anim";

const Kamp = () => {
  return (
    <motion.div
      exit="exit"
      initial="hidden"
      animate="show"
      variants={pageAnimation}
    >
      <StyledNews>
        <Container className="container" fluid="md">
          <Row>
            <Col xl align="center" className="text-center">
              <div className="heading">
                <h1>
                  <b>{news[4].text}</b>
                </h1>
              </div>
              <div className="text">
                <p>{news[4].desc}</p>
              </div>
            </Col>
            <Col xl className="images">
              <Row>
                <Col md>
                  <img src={img1} alt="" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </StyledNews>
    </motion.div>
  );
};

const StyledNews = styled.div`
  margin: 3rem;
  .text-center {
    margin: auto 2rem auto 0rem;
  }
  .text {
    margin-top: 2rem;
    font-size: 1.3rem;
    /* width: 70%; */
    padding: 1rem 1rem 0 0;
    color: black;
  }
  .images {
    margin-top: 2rem;
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
      margin: 1rem 2rem 1rem 0;
      border-radius: 1.5rem;
    }
    .flex-image {
      display: flex;
    }
  }
  @media screen and (max-width: 767px) {
    .images {
      margin-top: 0;
    }
    .text {
      margin-top: 0.3rem;
    }
  }
`;

export default Kamp;
