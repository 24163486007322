import React from "react";
// Pages
import HomePage from "./pages/HomePage";
import Kamp from "./pages/news/Kamp";
import B2b from "./pages/news/B2b";
import NovaG from "./pages/news/NovaG";
import Ffs from "./pages/news/Ffs";
import Bnsajam from "./pages/news/Bnsajam";
import SudskiTumac from "./pages/SudskiTumac";
import Orao from "./pages/news/Orao";
// Global styles
import GlobalStyles from "./components/GlobalStyles";
// Router
import { Switch, Route, useLocation } from "react-router-dom";
//Animation
import { AnimatePresence } from "framer-motion";
import Header from "./components/Header";
import Upis from "./pages/Upis";

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <GlobalStyles />
      <Header />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/upis" exact>
            <Upis />
          </Route>
          <Route path="/sudski-tumac" exact>
            <SudskiTumac />
          </Route>
          <Route path="/kamp-na-jahorini" exact>
            <Kamp />
          </Route>
          <Route path="/b2b-sarajevo" exact>
            <B2b />
          </Route>
          <Route path="/nova-godina" exact>
            <NovaG />
          </Route>
          <Route path="/ffs-sarajevo" exact>
            <Ffs />
          </Route>
          <Route path="/pokreni-nesto-dobro" exact>
            <Bnsajam />
          </Route>
          <Route path="/orao-ad" exact>
            <Orao />
          </Route>
        </Switch>
      </AnimatePresence>
    </div>
  );
}

export default App;
