import React from "react";
// styled components
import styled from "styled-components";
// images
import image1 from "./news/images/cambridge.JPG";

const Cambridge = () => {
  return (
    <StyledAbout>
      <h1 className="text-center">Priprema za CAMBRIDGE test.</h1>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="wave col-md-6 mt-4 justify-content-center align-items-center">
            <img src={image1} className="img-fluid hero-img" alt="" />
          </div>
          <div className="wave col-md-6 hero-text text-center">
            <p className="text-center">
              Steknite međunarodno priznati sertifikat o poznavanju engleskog
              jezika. Želite dobiti međunarodno priznati sertifikat? U našem
              centru možete izvršiti pripremu kroz kurseve engleskog jezika (od
              YLE do C2) ili kroz individualnu pripremu za polaganje nekog od
              Cambridge ispita ili IELTS ispita. Mi smo centar za pripremu i
              polaganje kandidata zajedno sa ovlaštenim ispitnim centrom -
              British Council.
            </p>
          </div>
        </div>
      </div>
    </StyledAbout>
  );
};

const StyledAbout = styled.div`
  position: relative;
  margin-top: 10rem;
  background: rgb(238, 174, 202);
  background: linear-gradient(
    0deg,
    rgba(238, 174, 202, 0.5) 0%,
    rgba(148, 187, 233, 0.7) 60%
  );
  z-index: 2;
  padding: 3rem;
  p {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .hero-img {
    border-radius: 1rem;
    margin-bottom: 2rem;
  }
  .hero-text {
    margin: auto;
  }
  h1 {
    font-weight: bold;
  }
`;

export default Cambridge;
