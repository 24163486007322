import React from "react";
// styled
import styled from "styled-components";
// bootstrap
import { Container, Row, Col } from "react-bootstrap";
// animations
import { motion } from "framer-motion";
import { pageAnimation } from "../anim";
// images
import img1 from "../img/mikar.jpg";
import img2 from "../img/orao.jpg";
import img3 from "../img/lykos.jpg";
import img4 from "../img/telrad.jpg";

const SudskiTumac = () => {
  return (
    <motion.div
      exit="exit"
      initial="hidden"
      animate="show"
      variants={pageAnimation}
    >
      <StyledNews>
        <Container className="container" fluid="md">
          <Row>
            <Col xl align="center" className="text-center shadow">
              <div className="heading">
                <h1>
                  <b>Sudski tumač za Engleski jezik.</b>
                </h1>
              </div>
              <div className="text">
                <p>
                  Usluge pismenog i usmenog prijevoda, sa ili bez ovjere sudskog
                  tumača za engleski jezik. Nudimo usluge prevođenja svih vrsta
                  tekstova, ličnih dokumenata i poslovne dokumentacije. Zbog
                  dugogodišnjeg iskustva i brojnih referenci, kao i prevoda u
                  raznim sferama (medicina, pravo, ekonomija, turizam,
                  građevina, bankarstvo i finansije..), ispunjavamo zahtjeve
                  klijenata u potpunosti.<br></br>
                  <br></br>Dostupni smo i online. <br></br>
                  <br></br>Kada je u pitanju usmeno prevođenje, nudimo uslugu
                  simultanog ili konsekutivnog prevođenja. Ukoliko organizujete
                  sastanak sa inostranim klijentom, seminar ili vjenčanje, mi
                  smo pravi izbor za Vas.
                </p>
              </div>
            </Col>
            <Col className="shadow">
              <div>
                <h2 className="align-items-center text-center">
                  Sa nama su sarađivali:
                </h2>
                <ul className="text">
                  <li>✔ Orao a.d. Bijeljina</li>
                  <li>✔ Geomatic d.o.o. Bijeljina</li>
                  <li>✔ Mikar d.o.o. Bijeljina</li>
                  <li>✔ Medicom d.o.o. Bijeljina</li>
                  <li>✔ Turistička organizacija grada Bijeljina</li>
                  <li>✔ Komisija za očuvanje nacionalnih spomenika</li>
                  <li>✔ Muzej Hercegovine</li>
                  <li>✔ Advokat Dražen Nikolić</li>
                  <li>✔ Telrad Net Bijeljina</li>
                  <li>✔ Termometal d.o.o. Bijeljina</li>
                  <li>✔ Klimaterm d.o.o. Bijeljina</li>
                  <li>✔ Tehnički institut Bijeljina </li>
                  <li>✔ ICM d.o.o. Bijeljina</li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className="images">
            <h1 className="text-center">Neki od naših pouzdanih klijenata.</h1>
            <Col md>
              <img src={img1} alt="" />
            </Col>
            <Col md>
              <img src={img2} alt="" />
            </Col>
            <Col md>
              <img src={img3} alt="" />
            </Col>
            <Col md>
              <img src={img4} alt="" />
            </Col>
          </Row>
        </Container>
      </StyledNews>
    </motion.div>
  );
};

const StyledNews = styled.div`
  .shadow {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
    border-radius: 3%;
    padding: 1rem;
  }
  margin: 3rem;
  .text-center {
    margin: auto 2rem auto 0rem;
  }
  .text {
    margin-top: 2rem;
    font-size: 1.3rem;
    /* width: 70%; */
    padding: 1rem 1rem 0 0;
    color: black;
  }
  .images {
    margin-top: 2rem;
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
      margin: 1rem 2rem 1rem 0;
      border-radius: 1rem;
    }
    .flex-image {
      display: flex;
    }
  }
  @media screen and (max-width: 767px) {
    .images {
      margin-top: 0;
    }
    .text {
      margin-top: 0.3rem;
    }
  }
`;

export default SudskiTumac;
