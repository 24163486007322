const news = [
  {
    text: "Centar InLingua u saradnji sa ORAO AD.",
    desc: "U periodu od 22.08.2022. do 26.08.2022. u kompaniji Orao a.d. vršena je resertifikaciona provjera usklađenosti sistema menadžmenta kvalitetom Orao a. d. u skladu sa zahtjevima standarda ISO9001 i EN9100. U sklopu naše dugogodišnje saradnje, angažovani smo i ove godine da budemo dio tima za prevođenje. Uvijek je zadovoljstvo poslovati sa ovom kompanijom i sjajnim ljudima koji tamo rade.",
    date: "27.08.2022.",
    id: 6,
    path: "orao-ad",
  },
  {
    text: 'Centar InLingua na sajmu "Pokreni nešto dobro"!',
    desc: 'Sajam preduzetništva u Bijeljini pod sloganom "Pokreni nešto dobro" održan je 24.03.2022. u etno selu Stanišić. Investicijska fondacija Impakt i grad Bijeljina zajedno sa svojim partnerima bili su organizatori ovog sjajnog događaja koji između ostalog ima za cilj promovisanje preduzetništva u našem gradu. Sajam je posjetio i ambasador Švajcarske u BiH Daniel Hunn koji je imao priliku da se upozna sa našim radom.',
    date: "25.03.2022.",
    id: 5,
    path: "pokreni-nesto-dobro",
  },
  {
    text: "Centar InLingua na Female Founders Summit u Sarajevu!",
    desc: "Centar Inlingua je 09.03. bio u Sarajevu u okviru Female Founders Summit-a organizovanog od strane sjajne Fondacije 787. Zadovoljstvo je bilo biti dio ove priče, steći nove kontakte i upoznati divne preduzetnice iz raznih dijelova BiH. Novi logo i vizuali, digitalizovana učionica i mentorska podrška su još jedan benefit ovog projekta. Centar InLingua je prepoznat kao mali brend u usponu i neizmjerno smo srećni zbog toga.",
    date: "16.03.2022.",
    id: 4,
    path: "ffs-sarajevo",
  },
  {
    text: "Centar InLingua njeguje koncept društveno odgovornog poslovanja.",
    desc: "Vrijeme je darivanja, pa su centar InLingua i očna kuća Lukić odlučili da obraduju nove mame na odjeljenju za ginekologiju tokom predstojećih praznika. Nadamo se da će se mame obradovati paketićima. \n Želimo vam srećnu i uspješnu novu godinu!",
    date: "29.12.2021.",
    id: 3,
    path: "nova-godina",
  },
  {
    text: "Centar za strane jezike InLingua na predstavljanju B2B platforme!",
    desc: "Centar za strane jezike InLingua je za vikend bio u Sarajevu, gdje je prisustvovao predstavljanju B2B platforme koju je za lokalno tržište kreirala Privredna komora Federacije BiH zajedno sa svojim partnerima. Imali smo mogućnost da prigodno predstavimo Centar i svoje usluge lokalnim medijima. B2B platforma je namijenjena ženama preduzetnicama iz cijele BiH i služi umrežavanju, postizanju veće vidljivosti biznisa i širenju kontakata. Digitalizacija je neminovna u vremenu u kome živimo, odrazila se i na naš predavački svijet u vrijeme pandemije i pomogla da uspješno sprovedemo nastavni proces.",
    date: "20.11.2021.",
    id: 2,
    path: "b2b-sarajevo",
  },
  {
    text: "Centar za strane jezike InLingua na kampu na Jahorini!",
    desc: "Kamp #gofordigitalidentity organizovan je na Jahorini od 21.-23.09.2021. od strane ICBL-a, gdje je 15 sjajnih preduzetnica i ideja izabrano od 60 prijavljenih, imalo priliku da radi na razvoju digitalne kampanje za svoj biznis. Sjajno iskustvo, a sve ono sto je naučeno će svakako biti iskorišteno da Centar InLingua bude sve bolji i prepoznatljiviji. Rastemo zajedno!",
    date: "29.09.2021.",
    id: 1,
    path: "kamp-na-jahorini",
  },
];

export default news;
