import React from "react";
// components
import Card from "./Card";
// data
import news from "./data";
// bootstrap
import { Container, Row, Col } from "react-bootstrap";
// router
import { Link } from "react-router-dom";
// styled
import styled from "styled-components";

const News = () => {
  return (
    <StyledNews>
      <Container className="mt-5">
        <h1 align="center">Vijesti.</h1>
        <Row>
          {news.map((article) => (
            <Col xs={12} sm={6} md={4} lg={3} key={article.id}>
              <Link to={article.path}>
                <Card text={article.text} date={article.date} />
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </StyledNews>
  );
};

const StyledNews = styled.div`
  h1 {
    font-size: 3.3rem;
  }
`;

export default News;
