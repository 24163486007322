import React from "react";
// Images
import SchoolImage from "../img/skola2.jpg";
// Styled
import styled from "styled-components";
// data
import { aboutText } from "../util";
// waves
import Wave from "./Wave";
// bootstrap
import { Button } from "react-bootstrap";
// Router
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <StyledAbout>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="wave col-md-6 mt-4 justify-content-center align-items-center">
            <img src={SchoolImage} className="img-fluid hero-img" alt="" />
          </div>
          <div className="wave col-md-6 hero-text text-center">
            <h1 className="text-center py-4">O nama.</h1>
            <p className="text-center">
              {aboutText.one}
              <br />
              <br />
              {aboutText.two}
              <br />
              <br />
              <b>{aboutText.three}</b>
              <br />
              <Button variant="primary" className="m-2" as={Link} to="/upis">
                Upiši se!
              </Button>
            </p>
          </div>
          <Wave />
        </div>
      </div>
    </StyledAbout>
  );
};

const StyledAbout = styled.div`
  position: relative;
  margin-top: 10rem;
  z-index: 2;
  p {
    font-size: 1.2rem;
  }
  .hero-img {
    border: 0.2rem solid #c0c1c2;
    border-radius: 2rem;
  }

  .hero-text {
    margin: auto;
  }
`;

export default AboutPage;
