import React from "react";
// Components
import AboutPage from "../components/AboutPage";
import Slider from "../components/Slider";
import Services from "../components/Services";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Comment from "../components/Comment";
import News from "../components/news/News";
// animations
import { motion } from "framer-motion";
import { pageAnimation } from "../anim";
import Cambridge from "../components/Cambridge";

const HomePage = () => {
  return (
    <motion.div
      exit="exit"
      initial="hidden"
      animate="show"
      variants={pageAnimation}
    >
      <Slider />
      <AboutPage />
      <Cambridge />
      <News />
      <Services />
      <Comment />
      <Contact />
      <Footer />
    </motion.div>
  );
};

export default HomePage;
