import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`


.navbar {
  background-color: #f4f4f4;
  height: 13vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  z-index: 20;
  white-space: nowrap;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  align-items: center;
  margin: 0.5rem 0rem 2rem 1rem;
  flex-grow: 1;
  cursor: pointer;
}
.navbar-logo img {
  margin-left: 2rem;
  width: 9rem;
  height: 9rem;
}

.nav-menu {
  display: flex;
  margin-right: 2rem;
  list-style-type: none;
}

.nav-links {
  flex: 1;
  color: #fff;
  text-decoration: none;
  border-bottom: 3px solid transparent;
}

.nav-item {
  margin: 0rem 1.5rem;
}

.nav-item a {
  color: #1b1b1b;
  text-decoration: none;
}
.nav-item a:hover {
  color: #0b0b0b;
  text-decoration: none;
}

.nav-item:after {
  content: "";
  display: block;
  align-self: right;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: rgba(238, 174, 202, 1);
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #f4f4f4;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-links {
    margin: 1.3rem 0 0 0;
    width: 100%;
    display: table;
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000;
  }
  .down {
    margin-bottom: 2rem;
  }
  .nav-item:hover:after {
    width: 30%;
    background: rgba(238, 174, 202, 1);
  }
}

@media screen and (max-width: 600px) {
  .navbar-logo img {
    margin-left: -1rem;
  }
}


.wave {
    z-index: 22;
  }
html,
body {
	height: 100%;
	font-size: 1rem;
	font-family: 'Montserrat', sans-serif;
	scroll-behavior: smooth;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style-type: none;
}
ul,ol{
	list-style-type: none;
}
a, li{
	text-decoration: none;
}
/* Gallery */
.gallery {
	margin: 0 0.65rem;
	height: 140vh;
}

.gallery-item {
	height: auto;
	margin: 0.5rem;
}

.gallery-item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
/* GALLERY MEDIA QUERY */
@media screen and (min-width: 640px) {
	.gallery {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(5, 300px);
		grid-auto-flow: row dense;
	}
	.gallery-item {
		margin: 0.7rem;
	}
	.gallery-item:first-child {
		grid-row: span 1;
	}
	.gallery-item:nth-child(2) {
		grid-column: 2 /3;
		grid-row: span 2;
	}
	.gallery-item:nth-child(6),
	.gallery-item:nth-child(8) {
		grid-row: span 2;
	}
}

@media screen and (min-width: 960px) {
	.gallery {
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: repeat(3, auto-flow);
	}
	.gallery-item:first-child,
	.gallery-item:nth-child(7),
	.gallery-item:nth-child(8) {
		grid-column: span 2;
		grid-row: span 1;
	}
	.gallery-item:nth-child(2) {
		grid-column: span 2;
		grid-row: span 2;
	}
}
@media screen and (max-width: 960px) {
	.gallery {
		height: auto;
	}
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1176px){
	.map iframe{
		width: 300px;
	}
}

@media only screen and (max-width: 994px) {
	.map iframe {
		width: 330px;
	}
	.map {
		margin: 0px auto;
	}
}


@media only screen and (max-width: 991px) {
	.map iframe{
		width: 500px;
	}
}

@media only screen and (max-width: 767px) {
	.hero-1 {
		margin-top: 1rem;
	}
	.svg-img-top {
		margin-top: -0.5rem;
	}
	.nav-item i {
		margin-right: 1.2rem;
	}
	.testimonials {
		margin-top: 0rem;
	}
	.icons-container,
	.ponuda,
	.ponude {
		overflow-x: hidden;
		overflow-y: hidden;
	}
	.flex{
		display: flex;
		flex-direction: column;
	}
	.reverse {
		display: flex;
		flex-direction: column-reverse;
	}
	.testimonials-img {
		margin: 0px auto 1rem auto;
	}
	.map {
		margin-bottom: 1rem;
	}
	.map iframe {
		width: 400px;
	}
	.gallery img {
		margin-bottom: 2rem;
	}
	.footer {
		height: auto;
		align-items: center;
		flex-direction: row;
	}
	.footer-copyright {
		margin-top: 2rem;
	}
	.footer-a {
		margin: 0;
	}
}

@media only screen and (max-width: 660px) {
	.view-text h1 {
		font-size: 2.6rem;
	}
	.down-1 p,
	.down-2 p,
	.down-3 p {
		font-size: 1rem;
	}
}

@media only screen and (max-width: 435px) {
	.map iframe {
		width: 350px;
	}
	.kontakt-naslov{
		margin-top: 5rem;
	}
}
@media only screen and (max-width: 383px) {
	.map iframe {
		width: 300px;
	}
	.view-text {
		top: 20%;
	}
}
@media only screen and (max-width: 330px) {
	.map iframe {
		width: 250px;
	}
	.view-text {
		top: 5%;
	}
	.down-1 p,
	.down-2 p,
	.down-3 p {
		font-size: 0.8rem;
	}
}

@media only screen and (max-width: 284px) {
	.view-text {
		top: 10%;
	}
}
`;

export default GlobalStyle;
