import React, { useState } from "react";
// css
// import "./NavBar.css";
// logo
import logo from "../img/Danijelaa.png";
// framer motino
import { motion } from "framer-motion";
// router
import { Link } from "react-router-dom";

const Header = () => {
  // State
  const [click, setClick] = useState(false);
  // State handlers
  const handleClick = () => setClick(!click);

  const headerAnim = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 1.5,
        staggerChildren: 0.25,
        ease: "easeIn",
      },
    },
  };

  const dropAnim = {
    hidden: { y: -100 },
    show: { y: 0, transition: { duration: 0.7 } },
  };

  return (
    <>
      <motion.nav
        className="navbar"
        variants={headerAnim}
        initial="hidden"
        animate="show"
      >
        <div className="nav-container">
          <motion.div
            className="nav-icon"
            onClick={handleClick}
            variants={headerAnim}
          >
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </motion.div>
          <motion.div className="navbar-logo" variants={dropAnim}>
            <Link to="/" className="nav-logo">
              <img src={logo} alt="" className="logo" id="logo" />
            </Link>
          </motion.div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <motion.li className="nav-item" variants={dropAnim}>
              <a href="/#logo" className="nav-links">
                Početna
              </a>
            </motion.li>
            <motion.li className="nav-item" variants={dropAnim}>
              <a href="/#ponuda" className="nav-links">
                Šta nudimo?
              </a>
            </motion.li>
            <motion.li className="nav-item" variants={dropAnim}>
              <a href="/#kontakt" className="nav-links">
                Kontakt
              </a>
            </motion.li>
            <motion.li className="nav-item" variants={dropAnim}>
              <Link to="/upis" className="nav-links">
                Upis 2024
              </Link>
            </motion.li>
            <motion.li className="nav-item down" variants={dropAnim}>
              <Link to="/sudski-tumac" className="nav-links">
                Sudski tumač za engleski
              </Link>
            </motion.li>
          </ul>
        </div>
      </motion.nav>
    </>
  );
};

export default Header;
